import DytePlugin from '@dytesdk/plugin-sdk';
import React, { useEffect, useState } from 'react'

const MainContext = React.createContext<any>({});

const MainProvider = ({ children }: { children: any }) => {
   
    const [plugin, setPlugin] = useState<DytePlugin>();

    const loadPlugin = async () => {
        const dytePlugin = DytePlugin.init();
        dytePlugin.stores.create("syncStore");
        window.dytePlugin = dytePlugin;
        setPlugin(dytePlugin);
    }

    useEffect(() => {
        loadPlugin();
        return () => {
            if (!plugin) return;
        }
    }, [])

    return (
        <MainContext.Provider value={{ plugin }}>
            {children}
        </MainContext.Provider>
    )
}

export { MainContext, MainProvider } 